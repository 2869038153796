import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { Link as GatsbyLink } from "gatsby";
import { mix } from "@theme-ui/color";
import { PostCard } from "../components/PostCard";
import { ProjectCard } from "../components/ProjectCard";
import { format } from "date-fns";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Divider = makeShortcode("Divider");
const Button = makeShortcode("Button");
const Link = makeShortcode("Link");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Text = makeShortcode("Text");
const SourceTags = makeShortcode("SourceTags");
const Badge = makeShortcode("Badge");
const SourceList = makeShortcode("SourceList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Alert variant="success" mdxType="Alert">I'm currently looking for new opportunities 😊</Alert>
    <Divider mdxType="Divider" />
    <h1><a parentName="h1" {...{
        "href": "#akshay-ashok"
      }}>{`akshay-ashok`}</a></h1>
    <p>{`Full Stack Developer based in India`}</p>
    <p>{`Microsoft Learn Student Ambassador, React, NodeJS, Express, MongoDB, PostgreSQL, Python, Django, Apollo/GraphQL.`}</p>
    <Button as="a" variant="success" href="/resume" sx={{
      mr: 2
    }} mdxType="Button">
  View CV/Resume
    </Button>
    <Link href="/akshay-resume.pdf" target="_blank" title="View CV" mdxType="Link">
  .pdf version
    </Link>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#intro"
      }}>{`intro`}</a></h2>
    <p>{`hey there! 👋 I'm Akshay, a self-taught full stack developer based in India. I like to build and develop
cool products. I mainly work with ReactJS for frontend and NodeJS or Python depending on the task.`}</p>
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: 'wrap'
    }} mdxType="Flex">
      <Box sx={{
        width: ["100%", "100%", "55%"],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">
        <h2>{`contact`}</h2>
        <Text mdxType="Text">Email</Text>
        <Link href="mailto:howdy.aks@gmail.com" target="_blank" variant="styles.a" mdxType="Link">
  howdy.aks@gmail.com
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">Twitter</Text>
        <Link href="https://twitter.com/aks2899" target="_blank" variant="styles.a" mdxType="Link">
  https://twitter.com/aks2899
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">GitHub</Text>
        <Link href="https://github.com/Akshay090" target="_blank" variant="styles.a" mdxType="Link">
  https://github.com/Akshay090
        </Link>
        <Divider mdxType="Divider" />
        <Divider mdxType="Divider" />
      </Box>
      <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
        <h2>{`blog topics`}</h2>
        <Divider mdxType="Divider" />
        <SourceTags mdxType="SourceTags">
  {tags => <Box mdxType="Box">
      {tags.sort((a, b) => b.percent - a.percent).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Badge key={index} variant="primary" sx={{
                mr: 2,
                mb: 2,
                color: mix("secondary", "primary", `${index / tags.length}`),
                borderColor: mix("secondary", "primary", `${index / tags.length}`)
              }} mdxType="Badge">
              {name}
            </Badge>;
            })}
    </Box>}
        </SourceTags>
        <Divider mdxType="Divider" />
        <Divider mdxType="Divider" />
      </Box>
    </Flex>
    <h2>{`projects`}</h2>
    <SourceList filter="projects" mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.splice(0, 6).map((edge, index) => {
          const {
            frontmatter: {
              title,
              misc,
              date,
              featuredImageUrl
            },
            fields: {
              slug
            }
          } = edge.node;
          return <ProjectCard key={index} index={index} cardImage={featuredImageUrl} title={title} misc={misc} date={date} slug={slug} mdxType="ProjectCard" />;
        })}
    </Flex>}
    </SourceList>
    <Divider mdxType="Divider" />
    <Flex sx={{
      justifyContent: "center"
    }} mdxType="Flex">
  <Box mdxType="Box">
    <Button as={GatsbyLink} variant="ghost" href="/projects" mdxType="Button">
      All Projects{" "}
      <Box as="span" role="img" aria-label="pencil" mdxType="Box">
        🛠
      </Box>
    </Button>
  </Box>
    </Flex>
    <Divider mdxType="Divider" />
    <h2>{`latest posts`}</h2>
    <Divider mdxType="Divider" />
    <SourceList filter="posts" mdxType="SourceList">
  {posts => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).reduce((posts, post) => {
          return post.node.frontmatter.pinned ? [post, ...posts] : [...posts, post];
        }, []).splice(0, 4).map((edge, index) => {
          const {
            frontmatter: {
              title,
              featuredImageUrl,
              tags,
              date,
              dateModified,
              pinned
            },
            excerpt,
            timeToRead,
            fields: {
              slug
            }
          } = edge.node;
          return <PostCard key={index} title={title} cardImage={index === 0 ? featuredImageUrl : null} tags={tags} date={date} dateModified={dateModified} excerpt={excerpt} slug={slug} pinned={pinned} timeToRead={timeToRead} mdxType="PostCard" />;
        })}
    </Flex>}
    </SourceList>
    <Divider mdxType="Divider" />
    <Flex sx={{
      justifyContent: "center"
    }} mdxType="Flex">
  <Box mdxType="Box">
    <Button as={GatsbyLink} variant="ghost" href="/posts" mdxType="Button">
      More posts{" "}
      <Box as="span" role="img" aria-label="pencil" mdxType="Box">
        ✏️
      </Box>
    </Button>
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      